import cx from "classnames";
import * as React from "react";
import { navigate } from "gatsby";

import { IconInbox } from "react-heroicons-ui";

import Post from "../components/Post";
import OuterContainer from "../components/OuterContainer";

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

export default () => {
  return (
    <>
      <Post
        html={""}
        id="hit-me-up-header"
        slug="/contact/"
        publishedAt="Feel free to pour your heart out."
        shouldPassThroughCloudinary={false}
        featureImage={
          "https://images.unsplash.com/photo-1466096115517-bceecbfb6fde?auto=format&fit=crop&w=1280&q=80"
        }
        title={"Hit me up"}
        tags={[]}
      />
      <OuterContainer className={cx("bg-white", "mt-8")}>
        <form
          action="/thanks/"
          name="hit-me-up"
          method="post"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          data-netlify-recaptcha="true"
        >
          <div className={"mb-8"}>
            <label
              htmlFor="name"
              className={cx(
                "block",
                "text-sm",
                "font-display",
                "text-gray-900"
              )}
            >
              Name
            </label>
            <div className={cx("mt-1", "relative", "border-solid", "border-2")}>
              <input
                id="name"
                name="name"
                className={cx("text-base", "p-2", "block", "w-full")}
              />
            </div>
          </div>

          <div className={"mb-8"}>
            <label
              htmlFor="email"
              className={cx(
                "block",
                "text-sm",
                "font-display",
                "text-gray-900"
              )}
            >
              Email
            </label>
            <div className={cx("mt-1", "relative", "border-solid", "border-2")}>
              <input
                id="email"
                name="email"
                className={cx("text-base", "p-2", "block", "w-full")}
              />
            </div>
          </div>

          <div className={"mb-8"}>
            <label
              htmlFor="message"
              className={cx(
                "block",
                "text-sm",
                "font-display",
                "text-gray-900"
              )}
            >
              Message
            </label>
            <div className={cx("mt-1", "relative", "border-solid", "border-2")}>
              <textarea
                id="message"
                name="message"
                className={cx("text-base", "p-2", "block", "w-full")}
              />
            </div>
          </div>

          <div className={"mb-8"} data-netlify-recaptcha="true"></div>

          <input type="hidden" name="form-name" value="hit-me-up" />

          <button
            type="submit"
            className={cx(
              "w-full",
              "bg-black",
              "text-white",
              "font-bold",
              "text-lg",
              "p-4",
              "flex",
              "justify-evenly",
              "transition-colors",
              "duration-300",
              "hover:text-black",
              "hover:bg-white"
            )}
          >
            <span className={cx("flex-grow", "text-left")}>Send</span>
            <IconInbox
              className={cx("fill-current", "flex-shrink-0", "flex-grow-0")}
            />
          </button>
        </form>
      </OuterContainer>
    </>
  );
};
