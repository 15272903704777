const CLOUDINARY_BASE_URL = "https://res.cloudinary.com/memories/image/fetch";
const CLOUDINARY_BASE_CONFIG = ["c_fit", "f_auto", "q_80"].join(",");

export default (value: string): string => {
  return value.replace(
    "https://sylvester.app/",
    `${CLOUDINARY_BASE_URL}/https://sylvester.app/`
  );
};

export const getCloudUrl = (
  value: string,
  width: number,
  extraConfig: string[] = []
): string =>
  value.replace(
    /\/q_auto\//,
    `/w_${width},${
      extraConfig.length > 0 ? `${extraConfig.join(",")},` : ""
    }${CLOUDINARY_BASE_CONFIG}/`
  );

export const makeBlurImage = (value: string): string => {
  return getCloudUrl(value, 24, ["e_blur:300"]);
};

export const makeSmallImage = (value: string): string => {
  return getCloudUrl(value, 640);
};

export const makeLargeImage = (value: string): string => {
  return getCloudUrl(value, 1280);
};

export const getSourceSet = (value: string): string => {
  return `${getCloudUrl(value, 720)} 1440w, 
  ${getCloudUrl(value, 540)} 1080w, 
  ${getCloudUrl(value, 576)} 768w, 
  ${getCloudUrl(value, 576)} 576w`;
};

interface GalleryImageArgs {
  masterString: string;
  replaceString: string;
  imageUrl: string;
}

export const makeGalleryImage = ({
  masterString,
  replaceString,
  imageUrl,
}: GalleryImageArgs): string => {
  return masterString.replace(
    replaceString,
    `src=${makeSmallImage(imageUrl)} data-zoom-src=${makeLargeImage(imageUrl)}`
  );
};
