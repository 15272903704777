import cx from "classnames";
import { Link } from "gatsby";
import * as React from "react";
import { capitalize } from "lodash";

import { IconArrowRight } from "react-heroicons-ui";

import { Post } from "../utils/gq-types";
import { StandardProps } from "../utils/element-type";
import { makeLargeImage } from "../utils/url-replace";

interface PostProps
  extends Post,
    StandardProps<React.HtmlHTMLAttributes<HTMLDivElement>, "title" | "id"> {
  shouldHaveLink?: boolean;
  shouldPassThroughCloudinary?: boolean;
}

const PostComponent: React.FunctionComponent<PostProps> = ({
  featureImage,
  title,
  tags,
  publishedAt,
  slug,
  shouldHaveLink = false,
  shouldPassThroughCloudinary = true,
  className,
}) => {
  const [isMounted, setIsMounted] = React.useState(false);
  React.useEffect(() => {
    if (!isMounted) {
      setIsMounted(true);
    }
  }, []);
  return (
    <div
      className={cx(
        "relative",
        "w-screen",
        {
          ["h-90vh"]: shouldHaveLink,
          ["h-50vh"]: !shouldHaveLink,
        },
        className
      )}
    >
      <div
        className={cx(
          "absolute",
          "z-30",
          "w-full",
          "top-1/2",
          "transform",
          "-translate-y-1/2"
        )}
      >
        <div
          className={cx(
            "w-full",
            "py-3",
            "px-10",
            "mr-auto",
            "ml-auto",
            "max-w-screen-sm",
            "md:max-w-screen-md",
            "lg:max-w-screen-lg",
            "xl:max-w-screen-xl",
            "leading-none",
            "transition-opacity-transform",
            "ease-in-out",
            "duration-1000",
            "transform",
            {
              ["translate-y-12"]: !isMounted,
              ["opacity-0"]: !isMounted,
            }
          )}
        >
          <h1
            className={cx(
              "font-display",
              "text-white",
              "text-5xl",
              "md:text-6xl",
              "mb-4",
              "md:mb-8",
              "text-center"
            )}
          >
            {title}
          </h1>
          <div
            className={cx(
              "mt-2",
              "flex",
              "flex-col",
              "md:flex-row",
              "align-middle",
              "justify-center",
              "text-white",
              "text-sm",
              "font-body",
              "text-center"
            )}
          >
            <span>{publishedAt}</span>
            {tags && tags.length > 0 && (
              <>
                <span
                  className={cx(
                    "hidden",
                    "md:block",
                    "inline-block",
                    "w-2",
                    "h-2",
                    "mx-4",
                    "my-1",
                    "leading-6",
                    "bg-white",
                    "rounded-full"
                  )}
                />
                <div className={cx("mt-4", "md:mt-0")}>
                  {tags.map(({ id, name }, index) => (
                    <span
                      key={id}
                      className={cx(
                        "inline-block",
                        "text-sm",
                        "font-body",
                        "text-white",
                        "px-2",
                        "cursor-default",
                        "border-transparent",
                        "border-solid",
                        "border-r-2",
                        {
                          [`border-white`]: index !== tags.length - 1,
                          [`pr-0`]: index === tags.length - 1,
                        }
                      )}
                    >
                      {capitalize(name)}
                    </span>
                  ))}
                </div>
              </>
            )}
          </div>
          {shouldHaveLink && (
            <div className={cx("mt-8", "flex", "justify-center")}>
              <Link
                className={cx(
                  "bg-transparent",
                  "border-white",
                  "border-solid",
                  "border-2",
                  "font-bold",
                  "px-4",
                  "py-2",
                  "text-white",
                  "text-md",
                  "hover:bg-white",
                  "hover:text-black",
                  "hover:border-white",
                  "duration-300",
                  "transition-all",
                  "flex"
                )}
                to={slug}
              >
                <span className={cx("p-1", "font-body")}>View</span>
                <IconArrowRight className={cx("ml-2", "fill-current")} />
              </Link>
            </div>
          )}
        </div>
      </div>
      <div
        className={cx(
          "absolute",
          "z-20",
          "top-0",
          "left-0",
          "right-0",
          "w-full",
          "h-full"
        )}
        style={{
          backgroundColor: `rgba(26, 26, 26, 0.3)`,
          backdropFilter: `brightness(30%) opacity(80%)`,
        }}
      />
      <div
        className={cx(
          "block",
          "overflow-hidden",
          "relative",
          "w-full",
          "h-full",
          "bg-no-repeat",
          "bg-center",
          "bg-cover"
        )}
        style={{
          backgroundImage: `url(${
            shouldPassThroughCloudinary
              ? makeLargeImage(featureImage)
              : featureImage
          })`,
        }}
      />
    </div>
  );
};

export default PostComponent;
